<template>
  <div>
    <AppNav />
    <main class="py-12 px-4">
      <slot />
    </main>
    <AppFooter />
  </div>
</template>

<style scoped>
main {
  min-height: calc(100vh - 3.5rem);
}
</style>
